import React from 'react';
import FooterLogo from './footerLogo'
import './footer.scss';

function Footer() {
  return (
    <div className='footer'>
        <FooterLogo />
    </div>
  );
}

export default Footer;
