// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  width: 100%;
  height: 20vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center; }
  .footer svg {
    width: 200px; }
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAA;EAN3B;IAQQ,YAAY,EAAA","sourcesContent":[".footer {\n    width: 100%;\n    height: 20vh;\n    background-color: black;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    svg {\n        width: 200px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
