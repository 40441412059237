import React, { useState, useEffect } from 'react';

import Gabriel1 from './gabriel1.webp';
import Gabriel2 from './gabriel2.webp';
import Gabriel3 from './gabriel3.webp';
import Gabriel4 from './gabriel4.webp';
import Gabriel5 from './gabriel5.webp';
import Gabriel6 from './gabriel6.webp';

import Hal1 from './hal1.webp';
import Hal2 from './hal2.webp';
import Hal3 from './hal3.webp';
import Hal4 from './hal4.webp';

import RadarRadio1 from './radarRadio1.webp';
import RadarRadio2 from './radarRadio2.webp';
import RadarRadio3 from './radarRadio3.webp';

import Osquello1 from './osquello1.webp';
import Osquello2 from './osquello2.webp';

function Photography() {
  
  return (
    <div className="photography">
        <div className='imageContainer gabriel1'>
            <img className='image' src={Gabriel1} alt='Gabriel1'/>
        </div>
        <div className='imageContainer gabriel2'>
            <img className='image' src={Gabriel2} alt='Gabriel2'/>
        </div>
        <div className='imageContainer gabriel3'>
            <img className='image' src={Gabriel3} alt='Gabriel3'/>
        </div>
        <div className='imageContainer gabriel4'>
            <img className='image' src={Gabriel4} alt='Gabriel4'/>
        </div>
        <div className='imageContainer gabriel5'>
            <img className='image' src={Gabriel5} alt='Gabriel5'/>
        </div>
        <div className='imageContainer gabriel6'>
            <img className='image' src={Gabriel6} alt='Gabriel6'/>
        </div>
        <div className='imageContainer hal1'>
            <img className='image' src={Hal1} alt='Hal1'/>
        </div>
        <div className='imageContainer hal2'>
            <img className='image' src={Hal2} alt='Hal2'/>
        </div>
        <div className='imageContainer hal3'>
            <img className='image' src={Hal3} alt='Hal3'/>
        </div>
        <div className='imageContainer hal4'>
            <img className='image' src={Hal4} alt='Hal4'/>
        </div>
        <div className='imageContainer radarRadio1'>
            <img className='image' src={RadarRadio1} alt='RadarRadio1'/>
        </div>
        <div className='imageContainer radarRadio2'>
            <img className='image' src={RadarRadio2} alt='RadarRadio2'/>
        </div>
        <div className='imageContainer radarRadio3'>
            <img className='image' src={RadarRadio3} alt='RadarRadio3'/>
        </div>
        <div className='imageContainer osquello1'>
            <img className='image' src={Osquello1} alt='Osquello1'/>
        </div>
        <div className='imageContainer osquello2'>
            <img className='image' src={Osquello2} alt='Osquello2'/>
        </div>
    </div>
  );
}

export default Photography;
