import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './index.scss';
import GarbsFavicon from './garbsFavicon.png';
import Header from './components/header/header';
import LandingSection from './components/landingSection/landingSection';
import Categories from './components/categories/categories';
import PhotographyPage from './components/photographyPage/photographyPage';
import FilmPage from './components/filmPage/filmPage';
import Footer from './components/footer/footer';
import Cursor from './components/cursor/cursor';

const HomepageLayout = () => {
  return (
    <>
    <Cursor />
    <Header />
    <LandingSection />
    <div className='contentContainer'>
      <Categories />
      <Footer />
    </div>
    </>
  );
};

const PhotographyLayout = () => {
  return (
    <>
    <Cursor />
    <Header />
    <PhotographyPage />
    <Footer />
    </>
  );
};

const FilmLayout = () => {
  return (
    <>
    <Cursor />
    <Header />
    <FilmPage />
    <Footer />
    </>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Homepage = () => {
  return (
    <div>
      <Helmet>
        <link rel="icon" href={GarbsFavicon} />
      </Helmet>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomepageLayout />} />
          <Route path="/photography" element={<PhotographyLayout />} />
          <Route path="/film" element={<FilmLayout />} />
        </Routes>
      </Router>
    </div>
  );
};

const homepageElement = document.getElementById('homepage');
const homepage = createRoot(homepageElement);
homepage.render(<Homepage />);
