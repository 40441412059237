import React, { useEffect, useState } from 'react';
import LandingBackground from './landingBackground.png';
import LandingLogo from './landingLogo';
import './landingSection.scss';

function LandingSection() {
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const progress = scrollY / window.innerHeight;

    const normalizedProgress = Math.min(1.4, Math.max(0, progress));

    setScrollProgress(normalizedProgress);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scale = 1 + scrollProgress; 
  const blur = 10 * scrollProgress; 

  const logoOpacity = Math.max(0, 1 - (scrollProgress - 0.20) * (1 / 0.20));

  return (
    <div className="landingSection">
      <img className='landingBackground' src={LandingBackground} alt='Landing Background Image' style={{ transform: `scale(${scale})`, filter: `blur(${blur}px)` }} />
      <div style={{ opacity: logoOpacity }}>
        <LandingLogo className='landingLogo'  />
      </div>
    </div>
  );
}

export default LandingSection;
