import React, { useState, useEffect } from 'react';
import './categories.scss';
import PhotographyHeroOne from './photographyHeroOne.png';
import PhotographyHeroTwo from './photographyHeroTwo.png';
import PhotographyHeroThree from './photographyHeroThree.png';
import PhotographyHeroFour from './photographyHeroFour.png';

import FilmHero from '../filmPage/films/filmPaths/jososick.mp4';

const Categories = () => {

    const handleMouseEnter = () => {
        const ball = document.querySelector(".ball");
        const cursor = document.querySelector(".cursor");
        const cursorText = document.querySelector(".cursor-text");
        
        ball.style.width = "100px";
        ball.style.height = "100px";
        cursor.style.opacity = "0";
        cursorText.style.opacity = "1";
        };

        const handleMouseLeave = () => {
        const ball = document.querySelector(".ball");
        const cursor = document.querySelector(".cursor");
        const cursorText = document.querySelector(".cursor-text");

        ball.style.width = "50px";
        ball.style.height = "50px";
        cursor.style.opacity = "1";
        cursorText.style.opacity = "0";
    };

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [opacity, setOpacity] = useState(Array(4).fill(0)); // Array to store opacity for each image

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex(prevIndex =>
                (prevIndex + 1) % 4 // Assuming you have 4 photography hero images
            );
        }, 1300);

        return () => clearInterval(interval);
    }, []); // Empty dependency array to run effect only once on component mount

    useEffect(() => {
        // Update opacity array when currentImageIndex changes
        const updatedOpacity = Array(4).fill(0);
        updatedOpacity[currentImageIndex] = 1; // Set opacity to 1 for the current image
        setOpacity(updatedOpacity);
    }, [currentImageIndex]);

    return (
        <div className="categories">
            <a className='photographySection' href='/photography' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <p className='title'>PHOTOGRAPHY</p>
                <p className='viewAll'>Explore</p>
                <div className='contentContainer'>
                    <div className='overlay'></div>
                    <div className='photographySlides'> 
                        <img src={PhotographyHeroOne} style={{ opacity: opacity[0] }} alt="Photography Hero Image 1" />
                        <img src={PhotographyHeroTwo} style={{ opacity: opacity[1] }} alt="Photography Hero Image 2" />
                        <img src={PhotographyHeroThree} style={{ opacity: opacity[2] }} alt="Photography Hero Image 3" />
                        <img src={PhotographyHeroFour} style={{ opacity: opacity[3] }} alt="Photography Hero Image 4" />
                    </div>
                </div>
            </a>
            <a className='filmSection' href='/film' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <p className='title'>FILM</p>
                <p className='viewAll'>Explore</p>
                <div className='contentContainer'>
                    <div className='overlay'></div>
                    <video id='jososickVideo' autoPlay muted loop playsInline preload="metadata" >
                        <source src={FilmHero} type="video/mp4" />
                    </video>
                </div>
            </a>
        </div>
    );
};
export default Categories;
