import React, { useState, useEffect, useRef } from 'react';
import Photography from './photography/photography';
import './photographyPage.scss';

function PhotographyPage() {
  const [activePhotographyIndex, setactivePhotographyIndex] = useState(0); // Index of the active film title
  const [isSticky, setIsSticky] = useState(false);
  const photographyRef = useRef(null);
  const headerHeight = 45; 

  useEffect(() => {
    const handleScroll = () => {
      const sectionTop = photographyRef.current.getBoundingClientRect().top;
      setIsSticky(sectionTop <= headerHeight);
        let activeIndex = 0;
        const mobile = window.innerWidth < 751;
        if (mobile) {
          if (sectionTop <= headerHeight) {
            activeIndex = 1;
          } 
          if (sectionTop <= headerHeight - (window.innerHeight * 2 - headerHeight * 2)) {
            activeIndex = 2;
          } 
          if (sectionTop <= headerHeight - (window.innerHeight * 4 - headerHeight * 4)) {
            activeIndex = 3;
          }
          if (sectionTop <= headerHeight - (window.innerHeight * 6 - headerHeight * 6)) {
            activeIndex = 4;
          }
        } else {
          if (sectionTop <= headerHeight) {
            activeIndex = 1;
          } 
          if (sectionTop <= headerHeight - (window.innerHeight - headerHeight)) {
            activeIndex = 2;
          } 
          if (sectionTop <= headerHeight - (window.innerHeight * 2 - headerHeight * 2)) {
            activeIndex = 3;
          }
          if (sectionTop <= headerHeight - (window.innerHeight * 3 - headerHeight * 3)) {
            activeIndex = 4;
          }
        }
        setactivePhotographyIndex(activeIndex);
  };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="photographyPage" ref={photographyRef}>
      <div className='headingContainer'>
        <p className='heading'>
          PHOTOGRAPHY
        </p>
      </div>
      <div className={`photographyTitle ${isSticky ? 'stick' : ''}`}>
          <p className={`${activePhotographyIndex === 1 ? 'active' : ''}`}>GABRIEL</p>
          <p className={`${activePhotographyIndex === 2 ? 'active' : ''}`}>HAL</p>
          <p className={`${activePhotographyIndex === 3 ? 'active' : ''}`}>RADAR RADIO</p>
          <p className={`${activePhotographyIndex === 4 ? 'active' : ''}`}>OSQUELLO</p>
      </div>
      <div>
        <Photography />
      </div>
    </div>
  );
}

export default PhotographyPage;
