// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landingSection {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: fixed; }
  .landingSection .landingBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: -1;
    pointer-events: none;
    user-select: none; }
  .landingSection .landingLogoContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    pointer-events: none;
    user-select: none; }
    .landingSection .landingLogoContainer .landingLogo {
      width: 100%; }
      @media screen and (max-width: 600px) {
        .landingSection .landingLogoContainer .landingLogo {
          width: 40%; } }
`, "",{"version":3,"sources":["webpack://./src/components/landingSection/landingSection.scss"],"names":[],"mappings":"AAMA;EACI,WAAW;EACX,aAAa;EACb,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,eAAe,EAAA;EANnB;IAQQ,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,8BAA8B;IAC9B,WAAW;IACX,oBAAoB;IACpB,iBAAiB,EAAA;EAjBzB;IAoBQ,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,oBAAoB;IACpB,iBAAiB,EAAA;IA9BzB;MAgCY,WAAW,EAAA;MArCnB;QAKJ;UAkCgB,UAAU,EAAA,EAEjB","sourcesContent":["@mixin below($width) {\n    @media screen and (max-width: $width) {\n      @content;\n    }\n}\n\n.landingSection {\n    width: 100%;\n    height: 100vh;\n    margin: 0;\n    padding: 0;\n    overflow: hidden;\n    position: fixed;\n    .landingBackground {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n        object-position: center center;\n        z-index: -1;\n        pointer-events: none;\n        user-select: none;\n    }\n    .landingLogoContainer {\n        position: absolute;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        z-index: 1;\n        pointer-events: none;\n        user-select: none;\n        .landingLogo {\n            width: 100%;\n            @include below(600px) {\n                width: 40%;\n            }\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
