// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  position: relative; }

body, html, button {
  cursor: none !important; }

* {
  cursor: none !important; }

.cursor {
  position: fixed;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: white;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 9999;
  cursor: none; }
  @media screen and (max-width: 700px) {
    .cursor {
      display: none; } }

.cursor-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  text-wrap: nowrap;
  letter-spacing: 2px;
  transition: opacity .1s ease;
  opacity: 0;
  margin: 0;
  color: white;
  cursor: none; }
  @media screen and (max-width: 700px) {
    .cursor-text {
      display: none; } }

.ball {
  background-color: rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.8);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  pointer-events: none;
  position: fixed;
  z-index: 9998;
  transform: translate(-50%, -50%);
  cursor: none; }
  @media screen and (max-width: 700px) {
    .ball {
      display: none; } }
`, "",{"version":3,"sources":["webpack://./src/components/cursor/cursor.scss"],"names":[],"mappings":"AAMA;EACI,kBAAkB,EAAA;;AAGtB;EACI,uBAAuB,EAAA;;AAG3B;EAAI,uBAAuB,EAAA;;AAE3B;EACI,eAAe;EACf,UAAU;EACV,WAAW;EACX,kBAAkB;EAClB,uBAAqC;EACrC,oBAAoB;EACpB,gCAAgC;EAChC,aAAa;EACb,YAAY,EAAA;EAxBZ;IAeJ;MAWQ,aAAa,EAAA,EAEpB;;AAED;EACI,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,4BAA4B;EAC5B,UAAU;EACV,SAAS;EACT,YAAY;EACZ,YAAY,EAAA;EA1CZ;IA8BJ;MAcQ,aAAa,EAAA,EAElB;;AAED;EACE,oCAAiC;EACjC,gBAAgB;EAChB,0CAAsC;EACtC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,aAAa;EACb,gCAAgC;EAChC,YAAY,EAAA;EA3DZ;IAgDF;MAaM,aAAa,EAAA,EAElB","sourcesContent":["@mixin below($width) {\n    @media screen and (max-width: $width) {\n      @content;\n    }\n}\n\nbody {\n    position: relative;\n}\n\nbody, html, button {\n    cursor: none !important;\n}\n\n* { cursor: none !important; }\n\n.cursor {\n    position: fixed;\n    width: 3px;\n    height: 3px;\n    border-radius: 50%;\n    background-color: rgba(255,255,255,1);\n    pointer-events: none;\n    transform: translate(-50%, -50%);\n    z-index: 9999;\n    cursor: none;\n    @include below(700px) {\n        display: none;\n    }\n}\n\n.cursor-text {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 1rem;\n    text-wrap: nowrap;\n    letter-spacing: 2px;\n    transition: opacity .1s ease;\n    opacity: 0;\n    margin: 0;\n    color: white;\n    cursor: none;\n    @include below(700px) {\n        display: none;\n    }\n  }\n\n  .ball {\n    background-color: rgba(0,0,0,0.2);\n    overflow: hidden;\n    border: 1px solid rgba(255,255,255,.8);\n    width: 50px;\n    height: 50px;\n    border-radius: 50%;\n    pointer-events: none;\n    position: fixed;\n    z-index: 9998;\n    transform: translate(-50%, -50%);\n    cursor: none;\n    @include below(700px) {\n        display: none;\n    }\n  }\n\n  \n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
