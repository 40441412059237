import React, { useState, useEffect, useRef } from 'react';
import Films from './films/films';
import './filmPage.scss';

function FilmPage() {
    const [isSticky, setIsSticky] = useState(false);
    const filmPageRef = useRef(null);
    const headerHeight = 45; 

    useEffect(() => {
        const handleScroll = () => {
            const sectionTop = filmPageRef.current.getBoundingClientRect().top;
            setIsSticky(sectionTop <= headerHeight);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div ref={filmPageRef} className='filmPage'>
            <p className={`heading ${isSticky ? 'stick' : ''}`}>
                FILM
            </p>
            <Films />
        </div>
    );
}

export default FilmPage;
