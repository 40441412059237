// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./BiggerDisplay.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Gruppo.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Bigger';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gruppo';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  max-width: 100vw;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: 'Bigger', sans-serif;
  overflow-x: hidden;
  background-color: black; }

.contentContainer {
  position: absolute;
  width: 100vw;
  left: 0;
  top: 150vh;
  z-index: 2; }
`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,+DAAkD;EAClD,mBAAmB;EACnB,kBAAkB,EAAA;;AAGpB;EACE,qBAAqB;EACrB,+DAA2C;EAC3C,mBAAmB;EACnB,kBAAkB,EAAA;;AAGpB;EACE,gBAAgB;EAChB,SAAS;EACT,mCAAmC;EACnC,iCAAiC;EACjC,kBAAkB;EAClB,uBAAuB,EAAA;;AAIzB;EACE,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,UAAU;EACV,UAAU,EAAA","sourcesContent":["@font-face {\n  font-family: 'Bigger';\n  src: url('./BiggerDisplay.otf') format('opentype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Gruppo';\n  src: url('./Gruppo.ttf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\nbody {\n  max-width: 100vw;\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  font-family: 'Bigger', sans-serif;\n  overflow-x: hidden;\n  background-color: black;\n}\n\n\n.contentContainer {\n  position: absolute;\n  width: 100vw;\n  left: 0;\n  top: 150vh;\n  z-index: 2;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
