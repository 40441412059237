// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: calc(100% - 24px);
  height: 21px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 12px;
  transition: background-color 0.2s ease;
  justify-content: space-between;
  /* Unvisited link  */
  /* Visited link    */
  /* Mouse over link */ }
  .header.fadeIn {
    background-color: black;
    transition: background-color 0.2s ease; }
  .header .headerLogo {
    width: 50px; }
  .header a {
    display: flex;
    text-decoration: none;
    align-items: center;
    gap: 12px; }
    .header a:hover {
      text-decoration: underline; }
    .header a p {
      color: white;
      font-family: 'Gruppo', sans-serif;
      -webkit-text-stroke: 1px white;
      line-height: 1;
      transform: translateY(-2px); }
  .header a {
    color: white; }
  .header a:visited {
    color: white; }
  .header a:hover {
    color: white; }
  .header a:active {
    color: white; }
`, "",{"version":3,"sources":["webpack://./src/components/header/header.scss"],"names":[],"mappings":"AAAA;EACI,wBAAwB;EACxB,YAAY;EACZ,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sCAAsC;EACtC,8BAA8B;EAwBL,oBAAA;EACA,oBAAA;EACA,oBAAA,EAAqB;EArClD;IAaQ,uBAAuB;IACvB,sCAAsC,EAAA;EAd9C;IAiBQ,WAAW,EAAA;EAjBnB;IAoBQ,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,SAAS,EAAA;IAvBjB;MAyBY,0BAA0B,EAAA;IAzBtC;MA4BY,YAAY;MACZ,iCAAiC;MACjC,8BAA8B;MAC9B,cAAc;MACd,2BAA2B,EAAA;EAhCvC;IAmCO,YAAW,EAAA;EAnClB;IAoCe,YAAW,EAAA;EApC1B;IAqCa,YAAW,EAAA;EArCxB;IAsCc,YAAW,EAAA","sourcesContent":[".header {\n    width: calc(100% - 24px);\n    height: 21px;\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 10;\n    display: flex;\n    align-items: center;\n    padding: 12px;\n    transition: background-color 0.2s ease;\n    justify-content: space-between;\n    &.fadeIn {\n        background-color: black;\n        transition: background-color 0.2s ease;\n    }\n    .headerLogo {\n        width: 50px;\n    }\n    a {\n        display: flex;\n        text-decoration: none;\n        align-items: center;\n        gap: 12px;\n        &:hover {\n            text-decoration: underline;\n        } \n        p {\n            color: white;\n            font-family: 'Gruppo', sans-serif;\n            -webkit-text-stroke: 1px white;\n            line-height: 1;\n            transform: translateY(-2px);\n        }\n    }\n    a {color:white;}         /* Unvisited link  */\n    a:visited {color:white;} /* Visited link    */\n    a:hover {color:white;}   /* Mouse over link */\n    a:active {color:white;}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
